const EmailData = {
  "North America": {
    Quote: "EmersonLLLPQuotes@stgocmxchange.emerson.com",
    SupportRequest: "SupportSupportRequest@stgocmxchange.emerson.com",
  },
  "Asia Pacific": {
    INDIA: {
      Quote: "AsiaIndiaMumbaiQuotes@stgocmxchange.emerson.com",
      SupportRequest: "AsiaIndiaMumbaiSalesOrders@stgocmxchange.emerson.com",
    },
    SINGAPORE: {
      Quote: "AsiaSingaporeQuotes@stgocmxchange.emerson.com",
      SupportRequest: "AsiaSingaporeSalesOrders@stgocmxchange.emerson.com",
    },
  },
  "Middle East and Africa": {
    DUBAI: {
      Quote: "MEAuaedubaiquote@stgocmxchange.emerson.com",
      SupportRequest: "ASCOM_MEAUAEDubaiSalesOrders@stgocmxchange.emerson.com",
    },
    DAR_AL_IBTIKAR: {
      Quote: "MEAIraqBasraQuote@stgocmxchange.emerson.com",
      SupportRequest: "MEAIraqBasraSalesOrders@stgocmxchange.emerson.com",
    },
    KUWAIT: {
      Quote: "MEAKuwaitKuwaitCityQuote@stgocmxchange.emerson.com",
      SupportRequest:
        "MEAKuwaitKuwaitCitySalesOrders@stgocmxchange.emerson.com",
    },
    MOROCCO: {
      Quote: "MEANAMoroccoQuote@stgocmxchange.emerson.com",
      SupportRequest: "MEANAMoroccoSalesOrders@stgocmxchange.emerson.com",
    },
    QATAR: {
      Quote: "MEAQatarDohaQuote@stgocmxchange.emerson.com",
      SupportRequest: "MEAQatarDohaSalesOrders@stgocmxchange.emerson.com",
    },
    "SAUDI ARABIA": {
      Quote: "MEASAAlKhobarQuote@stgocmxchange.emerson.com",
      SupportRequest: "MEASAAlKhobarSalesOrders@stgocmxchange.emerson.com",
    },
    "ABU DHABI": {
      Quote: "MEAUAEAbuDhabiQuote@stgocmxchange.emerson.com",
      SupportRequest: "MEAUAEAbuDhabiSalesOrders@stgocmxchange.emerson.com",
    },
    NIGERIA: {
      Quote: "MEAWANigeriaQuote@stgocmxchange.emerson.com",
      SupportRequest: "MEAWANigeriaSalesOrders@stgocmxchange.emerson.com",
    },
  },
};
export default EmailData;
